import { render, staticRenderFns } from "./itinerary_air_fake.vue?vue&type=template&id=181055ca&scoped=true&"
import script from "./itinerary_air_fake.vue?vue&type=script&lang=js&"
export * from "./itinerary_air_fake.vue?vue&type=script&lang=js&"
import style0 from "./itinerary_air_fake.vue?vue&type=style&index=0&id=181055ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "181055ca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/deploy/beta-web-new/web_head/releases/20250109092218/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('181055ca')) {
      api.createRecord('181055ca', component.options)
    } else {
      api.reload('181055ca', component.options)
    }
    module.hot.accept("./itinerary_air_fake.vue?vue&type=template&id=181055ca&scoped=true&", function () {
      api.rerender('181055ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/app/desktop/itineraries/itinerary_air_fake.vue"
export default component.exports